<template>
    <v-container
        v-resize="onResize"
        fluid
        class="overflow-y-hidden pa-3"
        ref="container"
        :style="{ height: '100%' }"
    >
        <v-progress-linear v-if="loading" indeterminate color="primary" />
        <v-data-table
            v-else
            id="virtual-scroll-table"
            disable-pagination
            hide-default-footer
            :headers="headers"
            :items="filteredForecasts"
            class="elevation-0 forecast-table"
            :mobile-breakpoint="0"
            :loading="loading"
            @click:row="openProject"
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4 align-center"
                    style="background-color: #eeeeee; display: flex; flex-wrap: wrap;"
                >
                    <!-- Desktop Buttons -->
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols="9" class="d-flex align-center">
                            <h2 class="ml-4">PROJECTS FORECAST</h2>
                            <v-spacer></v-spacer>
                            <div class="d-flex">
                                <v-btn
                                    @click="openReportSelect"
                                    color="primary"
                                    class="mr-2"
                                    rounded
                                >
                                    REPORT
                                </v-btn>
                                <v-btn
                                    @click="downloadReportByClient"
                                    color="primary"
                                    class="ml-2"
                                    rounded
                                >
                                    REPORT BY CLIENT
                                </v-btn>
                            </div>
                        </v-col>

                        <v-col cols="3" style="min-width: fit-content;">
                            <v-btn-toggle :style="{ width: '100%' }">
                                <v-btn
                                    @click.stop="showYears()"
                                    rounded
                                    small
                                    :style="{ width: '50%' }"
                                    >YEARS</v-btn
                                >
                                <v-btn
                                    @click.stop="showMonths"
                                    rounded
                                    small
                                    :style="{ width: '50%' }"
                                    >MONTHS</v-btn
                                >
                            </v-btn-toggle>
                        </v-col>
                    </template>
                </v-row>
                <!-- Buttons in mobile -->
                <div
                    v-if="$vuetify.breakpoint.smAndDown"
                    class="d-flex flex-column align-center justify-center"
                    style="background-color: #eeeeee;"
                >
                    <h2 class="ml-4">PROJECTS FORECAST</h2>
                    <v-btn-toggle :style="{ width: '75%' }" class="my-2">
                        <v-btn
                            @click.stop="showYears()"
                            rounded
                            small
                            :style="{ width: '50%' }"
                            >YEARS</v-btn
                        >
                        <v-btn
                            @click.stop="showMonths"
                            rounded
                            small
                            :style="{ width: '50%' }"
                            >MONTHS</v-btn
                        >
                    </v-btn-toggle>
                    <v-btn
                        @click="openReportSelect"
                        color="primary"
                        class="my-2"
                        rounded
                    >
                        REPORT
                    </v-btn>
                    <v-btn
                        @click="downloadReportByClient"
                        color="primary"
                        rounded
                    >
                        REPORT BY CLIENT
                    </v-btn>
                </div>

                <!-- Indicators in Mobile -->
                <v-menu offset-y v-if="$vuetify.breakpoint.smAndDown">
                    <template v-slot:activator="{ on, attrs }">
                        <span
                            v-bind="attrs"
                            v-on="on"
                            class="pt-3 d-flex justify-center align-center text-center text-subtitle-1 font-weight-medium"
                            style="cursor: pointer; display: block; width: 100%; background-color: #eeeeee"
                            color="primary"
                        >
                            <p class="d-flex align-center">
                                <v-icon class="mr-1" color="primary"
                                    >mdi-chart-bar</v-icon
                                >
                                Show Indicators
                            </p>
                        </span>
                    </template>

                    <v-card min-width="300px">
                        <v-list>
                            <v-list-item
                                v-for="(insight, index) in insights.slice(0, 4)"
                                :key="index"
                            >
                                <v-list-item-content
                                    class="d-flex align-center"
                                >
                                    <v-chip
                                        class="white--text font-weight-bold"
                                        :color="insight.color"
                                        label
                                    >
                                        <v-icon class="mr-1">{{
                                            insight.icon
                                        }}</v-icon>
                                        {{ insight.title }}
                                    </v-chip>
                                    <div>
                                        <v-list-item-subtitle
                                            class="text-subtitle-1 font-weight-bold black--text"
                                        >
                                            <template v-if="index === 0">
                                                {{
                                                    parseCurrency(
                                                        totalProjectsB2B()
                                                    )[0] +
                                                        ' - ' +
                                                        parseCurrency(
                                                            totalProjectsB2B()
                                                        )[1]
                                                }}
                                            </template>
                                            <template v-else-if="index === 1">
                                                {{
                                                    formatCurrency(
                                                        'USD',
                                                        totalInvoicedInMonth()
                                                            .usd
                                                    ) +
                                                        ' - ' +
                                                        formatCurrency(
                                                            'COP',
                                                            totalInvoicedInMonth()
                                                                .cop
                                                        )
                                                }}
                                            </template>
                                            <template v-else-if="index === 2">
                                                {{
                                                    totalActualForecasted().toFixed(
                                                        2
                                                    )
                                                }}%
                                            </template>
                                            <template v-else>
                                                {{
                                                    totalForecastedPercentage().toFixed(
                                                        2
                                                    )
                                                }}%
                                            </template>
                                        </v-list-item-subtitle>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>

                <v-row
                    v-if="$vuetify.breakpoint.mdAndUp"
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4 align-center"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <div class="d-flex py-2">
                        <template>
                            <v-card
                                class="pt-3 pl-2 pr-5 mx-3"
                                min-width="180px"
                                height="75px"
                            >
                                <v-card-text
                                    class="d-flex my-0 py-0 mx-0 px-0"
                                    width="100%"
                                >
                                    <v-chip
                                        class="mb-1 mt-0 mx-2 pt-0 white--text"
                                        :color="insights[0].color"
                                        label
                                        :style="{ height: '50px' }"
                                    >
                                        <v-icon center>
                                            {{ insights[0].icon }}
                                        </v-icon>
                                    </v-chip>
                                    <div class="mb-1 mt-0 pt-0">
                                        <div
                                            class="d-flex mr-0 pr-0 text-capitalize"
                                        >
                                            <span
                                                :style="{
                                                    'font-size': '14px',
                                                    'font-weight': 'bold',
                                                }"
                                                >{{ insights[0].title }}</span
                                            >
                                        </div>
                                        <p class="text-h6 black--text">
                                            {{
                                                parseCurrency(
                                                    totalProjectsB2B()
                                                )[0] +
                                                    ' - ' +
                                                    parseCurrency(
                                                        totalProjectsB2B()
                                                    )[1]
                                            }}
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </div>

                    <div class="d-flex py-2">
                        <template>
                            <v-card
                                class="pt-3 pl-2 pr-5 mx-3"
                                min-width="180px"
                                height="75px"
                            >
                                <v-card-text
                                    class="d-flex my-0 py-0 mx-0 px-0"
                                    width="100%"
                                >
                                    <v-chip
                                        class="mb-1 mt-0 mx-2 pt-0 white--text"
                                        :color="insights[1].color"
                                        label
                                        :style="{ height: '50px' }"
                                    >
                                        <v-icon center>
                                            {{ insights[1].icon }}
                                        </v-icon>
                                    </v-chip>
                                    <div class="mb-1 mt-0 pt-0">
                                        <div
                                            class="d-flex mr-0 pr-0 text-capitalize"
                                        >
                                            <span
                                                :style="{
                                                    'font-size': '14px',
                                                    'font-weight': 'bold',
                                                }"
                                                >{{ insights[1].title }}</span
                                            >
                                        </div>
                                        <p class="text-h6 black--text">
                                            {{
                                                formatCurrency(
                                                    'USD',
                                                    totalInvoicedInMonth().usd
                                                ) +
                                                    ' - ' +
                                                    formatCurrency(
                                                        'COP',
                                                        totalInvoicedInMonth()
                                                            .cop
                                                    )
                                            }}
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </div>
                    <div class="d-flex py-2">
                        <template>
                            <v-card
                                class="pt-3 pl-2 pr-5 mx-3"
                                min-width="180px"
                                height="75px"
                            >
                                <v-card-text
                                    class="d-flex my-0 py-0 mx-0 px-0"
                                    width="100%"
                                >
                                    <v-chip
                                        class="mb-1 mt-0 mx-2 pt-0 white--text"
                                        :color="insights[2].color"
                                        label
                                        :style="{ height: '50px' }"
                                    >
                                        <v-icon center>
                                            {{ insights[2].icon }}
                                        </v-icon>
                                    </v-chip>
                                    <div class="mb-1 mt-0 pt-0">
                                        <div
                                            class="d-flex mr-0 pr-0 text-capitalize"
                                        >
                                            <span
                                                :style="{
                                                    'font-size': '14px',
                                                    'font-weight': 'bold',
                                                }"
                                                >{{ insights[2].title }}</span
                                            >
                                        </div>
                                        <p class="text-h6 black--text">
                                            {{
                                                totalActualForecasted().toFixed(
                                                    2
                                                )
                                            }}%
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </div>
                    <div class="d-flex py-2">
                        <template>
                            <v-card
                                class="pt-3 pl-2 pr-5 mx-3"
                                min-width="180px"
                                height="75px"
                            >
                                <v-card-text
                                    class="d-flex my-0 py-0 mx-0 px-0"
                                    width="100%"
                                >
                                    <v-chip
                                        class="mb-1 mt-0 mx-2 pt-0 white--text"
                                        :color="insights[3].color"
                                        label
                                        :style="{ height: '50px' }"
                                    >
                                        <v-icon center>
                                            {{ insights[3].icon }}
                                        </v-icon>
                                    </v-chip>
                                    <div class="mb-1 mt-0 pt-0">
                                        <div
                                            class="d-flex mr-0 pr-0 text-capitalize"
                                        >
                                            <span
                                                :style="{
                                                    'font-size': '14px',
                                                    'font-weight': 'bold',
                                                }"
                                                >{{ insights[3].title }}
                                            </span>
                                        </div>
                                        <p class="text-h6 black--text">
                                            {{
                                                totalForecastedPercentage().toFixed(
                                                    2
                                                )
                                            }}
                                            %
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </div>
                    <v-spacer />
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.projectManager`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="projectManagerToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.forecastedPercentage`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="forecastedPercentageToFilter"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0 text-capitalize">
                        {{ item.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.projectManager`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0 text-capitalize" v-if="users.length > 0">
                        {{ setUserName(item.projectManager) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.lastUpdate`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0 text-capitalize">
                        {{ setLastUpdate(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.projectPrice`]="{ item }">
                <div class="d-flex justify-end">
                    <p class="my-0 text-capitalize">
                        {{ formatCurrency(item.currency, item.price || 0) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.balanceToBill`]="{ item }">
                <div class="d-flex justify-end">
                    <p class="my-0 text-capitalize">
                        {{
                            formatCurrency(
                                item.currency,
                                (item.price || 0) - (item.totalInvoiced || 0)
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.baseBalanceToBill`]="{ item }">
                <div class="d-flex justify-end">
                    <p class="my-0 text-capitalize">
                        {{
                            formatCurrency(
                                item.currency,
                                item.baseBalanceToBill
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.invoicedThisMonth`]="{ item }">
                <div class="d-flex justify-end">
                    <p class="my-0 text-capitalize d-flex justify-end">
                        {{
                            formatCurrency(
                                item.currency,
                                item.invoicedThisMonth
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.invoicedThisYear`]="{ item }">
                <div class="d-flex justify-end">
                    <p class="my-0 text-capitalize d-flex justify-end">
                        {{
                            formatCurrency(item.currency, item.invoicedThisYear)
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.actualVsForecastedInitial`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0 text-capitalize d-flex justify-end">
                        {{
                            calculationActualVsForecasted(
                                item.invoicedThisMonth,
                                item.newTotalAmount
                            ) > 0
                                ? calculationActualVsForecasted(
                                      item.invoicedThisMonth,
                                      item.newTotalAmount
                                  ).toFixed(2)
                                : '-'
                        }}
                        %
                    </p>
                </div>
            </template>
            <template v-slot:[`item.forecastedPercentage`]="{ item }">
                <div class="d-flex justify-center">
                    <p
                        class="my-0 text-capitalize"
                        :class="{
                            'text-orange': item.forecastedPercentage < 100,
                            'text-green': item.forecastedPercentage == 100,
                            'text-red': item.forecastedPercentage > 100,
                        }"
                    >
                        {{ item.forecastedPercentage }}%
                    </p>
                </div>
            </template>
            <template v-slot:[`item.date`]="{ item, header }">
                <v-col>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <span v-on="on">
                                <p
                                    class="d-flex justify-end align-right my-1"
                                    v-if="
                                        getDatePercentage(item, header.month) >
                                            0
                                    "
                                >
                                    {{ getDatePercentage(item, header.month) }}%
                                </p>
                                <p
                                    class="d-flex justify-end align-right mb-1"
                                    v-if="getDateAmount(item, header.month)"
                                >
                                    {{
                                        formatCurrency(
                                            item.currency,
                                            getDateAmount(item, header.month)
                                        )
                                    }}
                                </p>
                            </span>
                        </template>

                        <div class="d-flex flex-column">
                            <span
                                v-if="
                                    item.forecastsReferences &&
                                        item.forecastsReferences[header.month]
                                "
                            >
                                <strong>Reference: </strong>
                                {{
                                    formatCurrency(
                                        item.currency,
                                        item.forecastsReferences[header.month]
                                            .amount || 'No data'
                                    )
                                }}
                                ({{
                                    item.forecastsReferences[
                                        header.month
                                    ].percentage.toFixed(2) || 'No data'
                                }}%)
                            </span>
                            <span v-else>
                                <strong>Reference: </strong> No data
                            </span>

                            <span
                                v-if="
                                    item.currentForecasts &&
                                        item.currentForecasts[header.month]
                                "
                            >
                                <strong>Current: </strong>
                                {{
                                    formatCurrency(
                                        item.currency,
                                        item.currentForecasts[header.month]
                                            .amount || 'No data'
                                    )
                                }}
                                ({{
                                    item.currentForecasts[
                                        header.month
                                    ].percentage.toFixed(2) || 'No data'
                                }}%)
                            </span>
                            <span v-else>
                                <strong>Current: </strong> No data
                            </span>
                        </div>
                    </v-tooltip>
                </v-col>
            </template>
            <template v-slot:[`item.dateYear`]="{ item, header }">
                <v-col>
                    <p class="my-1" v-if="getDatePercentage(item, header.year)">
                        {{ getDatePercentage(item, header.year) }}
                        %
                    </p>
                    <p class="mb-1" v-if="getDateAmount(item, header.year)">
                        {{
                            formatCurrency(
                                item.currency,
                                getDateAmount(item, header.year)
                            )
                        }}
                    </p>
                </v-col>
            </template>
            <!--FOOTER-->
            <template v-slot:[`body.append`] class="mb-5">
                <tr :style="{ 'background-color': '#eeeeee' }">
                    <td
                        class="font-weight-bold"
                        :style="{ 'background-color': '#eeeeee' }"
                    ></td>
                    <td class="font-weight-bold">
                        TOTALS
                    </td>
                    <td class="font-weight-bold"></td>
                    <!-- Project Price -->
                    <td class="font-weight-bold">
                        <p class="d-flex justify-end align-right my-0">
                            {{ parseCurrency(totalProjectsPrice())[0] }}
                        </p>
                        <p class="d-flex justify-end align-right my-0">
                            {{ parseCurrency(totalProjectsPrice())[1] }}
                        </p>
                    </td>
                    <!-- Current Balance To Bill -->
                    <td class="font-weight-bold">
                        <p class="d-flex justify-end align-right my-0">
                            {{ parseCurrency(totalProjectsB2B())[0] }}
                        </p>
                        <p class="d-flex justify-end align-right my-0">
                            {{ parseCurrency(totalProjectsB2B())[1] }}
                        </p>
                    </td>
                    <!-- Base Balance To Bill -->
                    <td class="font-weight-bold">
                        <p class="d-flex justify-end align-right my-0">
                            {{
                                formatCurrency(
                                    'USD',
                                    totalProjectsBaseB2B().usd
                                )
                            }}
                        </p>
                        <p class="d-flex justify-end align-right my-0">
                            {{
                                formatCurrency(
                                    'COP',
                                    totalProjectsBaseB2B().cop
                                )
                            }}
                        </p>
                    </td>
                    <td class="font-weight-bold">
                        <p class="d-flex justify-end align-right my-0">
                            {{
                                formatCurrency(
                                    'USD',
                                    totalInvoicedInMonth().usd
                                )
                            }}
                        </p>
                        <p class="d-flex justify-end align-right my-0">
                            {{
                                formatCurrency(
                                    'COP',
                                    totalInvoicedInMonth().cop
                                )
                            }}
                        </p>
                    </td>
                    <td class="font-weight-bold"></td>
                    <td v-if="viewType != 'year'" class="font-weight-bold"></td>
                    <template v-if="viewType == 'month'">
                        <td
                            class="mt-4 ml-1 font-weight-bold"
                            v-for="(header, index) in headers.slice(9)"
                            :key="index"
                        >
                            <p
                                class="d-flex justify-end align-right my-0"
                                v-if="viewType == 'month'"
                            >
                                {{
                                    parseCurrency(totalPerDate(header.month))[0]
                                }}
                            </p>
                            <p
                                class="d-flex justify-end align-right my-0"
                                v-if="viewType == 'month'"
                            >
                                {{
                                    parseCurrency(totalPerDate(header.month))[1]
                                }}
                            </p>
                        </td>
                    </template>

                    <template v-if="viewType == 'year'">
                        <td
                            class="mt-4 ml-1 font-weight-bold"
                            v-for="(header, index) in headers.slice(8)"
                            :key="index"
                        >
                            <p
                                class="d-flex justify-end align-right my-0"
                                v-if="viewType == 'year'"
                            >
                                {{
                                    parseCurrency(totalPerDate(header.text))[0]
                                }}
                            </p>
                            <p
                                class="d-flex justify-end align-right my-0"
                                v-if="viewType == 'year'"
                            >
                                {{
                                    parseCurrency(totalPerDate(header.text))[1]
                                }}
                            </p>
                        </td>
                    </template>
                </tr>
            </template>
        </v-data-table>
        <!-- project  -->
        <v-dialog
            :retain-focus="false"
            v-model="detailView"
            fullscreen
            ref="dialogQuotesBySection"
        >
            <v-card v-if="detailView" fullscreen>
                <v-card-title class="ml-n4">
                    <v-btn icon @click="closeDetailView">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Forecast Details - {{ selectedProject.name }}
                </v-card-title>
                <v-card-text class="px-0">
                    <B2BForecast
                        class="px-6"
                        v-if="detailView"
                        :projectId="selectedProject.id"
                        @setUpdate="setUpdate"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- type of report selector -->
        <v-dialog max-width="450px" v-model="openReportSelector" persistent>
            <v-card>
                <v-card-title v-if="openReportSelector" class="text-h5"
                    >Generate Report</v-card-title
                >
                <v-card-text v-if="openReportSelector">
                    <p>Select the report type and currency.</p>
                    <v-select
                        v-model="selectedCurrency"
                        :items="currencies"
                        label="Select the currency to generate the report."
                        item-text="name"
                        item-value="code"
                    ></v-select>
                    <v-text-field
                        v-if="selectedCurrency == 'USD'"
                        v-model="copTRM"
                        label="COP TRM"
                        type="number"
                        :rules="[v => !!v || 'TRM is required']"
                    ></v-text-field>
                    <v-text-field
                        v-if="selectedCurrency == 'COP'"
                        v-model="usdTRM"
                        label="COP TRM"
                        type="number"
                        :rules="[v => !!v || 'TRM is required']"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeReportSelect">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="green"
                        :disabled="
                            loading ||
                                !selectedCurrency ||
                                (selectedCurrency === 'USD' &&
                                    (copTRM <= 0 || !copTRM)) ||
                                (selectedCurrency === 'COP' &&
                                    (usdTRM <= 0 || !usdTRM))
                        "
                        @click="getTRM('monthlyReport')"
                    >
                        Monthly
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        :disabled="
                            loading ||
                                !selectedCurrency ||
                                (selectedCurrency === 'USD' &&
                                    (copTRM <= 0 || !copTRM)) ||
                                (selectedCurrency === 'COP' &&
                                    (usdTRM <= 0 || !usdTRM))
                        "
                        @click="getTRM('weeklyReport')"
                    >
                        Weekly
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import API from '@/services/api'
import _ from 'lodash'
import * as XLSX from 'xlsx/xlsx.mjs'

export default {
    name: 'Forecast',
    components: {
        B2BForecast: () => import('@/components/Forecast/B2BForecast.vue'),
    },
    data() {
        return {
            height: 0,
            dateToShow: [],
            hideMonths: false,
            originalHeaders: [
                {
                    text: 'PROJECT',
                    value: 'project',
                    align: 'center',
                    sortable: false,
                    width: 200,
                    class: 'gray-background fixed-col',
                },
                {
                    text: 'PROJECT MANAGER',
                    value: 'projectManager',
                    align: 'center',
                    sortable: false,
                    class: 'gray-background',
                    width: 180,
                },
                {
                    text: 'LAST UPDATE',
                    value: 'lastUpdate',
                    align: 'center',
                    sortable: false,
                    class: 'gray-background',
                    width: 120,
                },
                {
                    text: 'PROJECT PRICE',
                    value: 'projectPrice',
                    align: 'center',
                    sortable: false,
                    width: 150,
                    class: 'gray-background',
                },
                {
                    text: 'CURRENT BALANCE TO BILL',
                    value: 'balanceToBill',
                    align: 'center',
                    sortable: false,
                    width: 150,
                    class: 'gray-background',
                },
                {
                    text: 'BASE BALANCE TO BILL',
                    value: 'baseBalanceToBill',
                    align: 'center',
                    sortable: false,
                    width: 150,
                    class: 'gray-background',
                },
            ],
            projects: [],
            projectToFilter: null,
            projectManagerToFilter: null,
            forecastedPercentageToFilter: null,
            users: [],
            months: [
                'JAN',
                'FEB',
                'MAR',
                'APR',
                'MAY',
                'JUN',
                'JUL',
                'AUG',
                'SEP',
                'OCT',
                'NOV',
                'DEC',
            ],
            loading: false,
            quotes: [],
            viewType: 'month',
            headers: [],
            quoteHeaders: [],
            detailView: false,
            selectedProject: {},
            clients: [],
            currentDate: '',
            currentMonth: '',
            dataReceive: null,
            insights: [
                {
                    color: '#ffa414',
                    icon: 'mdi-receipt-clock-outline',
                    iconColor: '#1A5276',
                    title: 'CURRENT BALANCE TO BILL',
                },
                {
                    color: '#20844c',
                    icon: 'mdi-invoice-check-outline',
                    iconColor: '#1A5276',
                    title: `INVOICED THIS MONTH`,
                },
                {
                    color: '#3498DB',
                    icon: 'mdi-finance',
                    iconColor: '#2874A6',
                    title: 'ACTUAL VS FORECASTED',
                },
                {
                    color: '#F08845',
                    icon: 'mdi-percent-outline',
                    iconColor: 'primary',
                    title: 'FORECASTED PERCENTAGE',
                },
            ],
            openReportSelector: false,
            currency: '',
            copTRM: undefined,
            usdTRM: undefined,
            formValid: false,
            action: '',
            currencies: ['COP', 'USD'],
            selectedCurrency: '',
            companyUsdExchange: undefined,
        }
    },
    computed: {
        filteredForecasts() {
            let conditions = []

            if (this.projectToFilter) {
                conditions.push(this.filterByProject)
            }

            if (this.projectManagerToFilter) {
                conditions.push(this.filterByProjectManager)
            }

            if (this.forecastedPercentageToFilter) {
                conditions.push(this.filterByForecastedPercentage)
            }

            if (conditions.length > 0) {
                return this.projects.filter(project => {
                    return conditions.every(condition => {
                        return condition(project)
                    })
                })
            }

            let forecasts = [...this.projects]
            forecasts.sort(
                (a, b) => b.forecastedPercentage - a.forecastedPercentage
            )
            return this.sumNewAmount(this.projects)
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.projects = await API.getProjects({})
            // exclude generic projects
            this.projects = this.projects.filter(project => !project.generic)
            // get users
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            // get quotes
            this.quotes = await API.getLiteQuotes()
            // get clients
            const {
                data: { clients },
            } = await API.getLiteClients()
            this.clients = clients
            // get settings
            const {
                data: { settings },
            } = await API.getSettings()
            // get usd exchange
            settings.forEach(setting => {
                if (setting.name == 'Exchange' && setting.exchanges) {
                    setting.exchanges.forEach(exchange => {
                        if (exchange.currency == 'USD') {
                            this.companyUsdExchange = exchange.money
                        }
                    })
                }
            })
            let currentDate = new Date()
            this.currentDate = `${currentDate.getFullYear()}-${String(
                currentDate.getMonth() + 1
            ).padStart(2, '0')}`
            // set Quotes information for each project
            this.projects.forEach(project => {
                project.forecastQuotes = []
                if (project.quotes) {
                    project.quotes.forEach(quote => {
                        const projectQuote = this.quotes.find(
                            q => q.id == quote.split('/')[0]
                        )
                        if (projectQuote) {
                            project.forecastQuotes.push(projectQuote)
                        }
                    })
                }
            })

            // set forecast information for each quote
            this.projects.forEach(project => {
                if (project.forecast) {
                    project.forecast.forEach(forecast => {
                        const quoteIndex = project.forecastQuotes.findIndex(
                            quote => quote.id == forecast.id
                        )
                        if (quoteIndex > -1) {
                            const forecastCopy = _.cloneDeep(forecast)
                            delete forecastCopy.id
                            project.forecastQuotes[quoteIndex] = {
                                ...project.forecastQuotes[quoteIndex],
                                ...forecast,
                            }
                        }
                    })
                }
            })
            this.setHeaders()
            this.showMonths()
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            try {
                await this.getInvoices()
                for (let project of this.projects) {
                    project.forecastedPercentage = this.calculateForecastedPercentage(
                        project
                    ).percentage
                    if (project.forecastV2) {
                        project.forecastsReferences =
                            project.forecastsReferences || {}
                        project.currentForecasts =
                            project.currentForecasts || {}

                        // set forecast data
                        Object.values(project.forecastV2.scopes).forEach(
                            scope => {
                                if (scope.dates) {
                                    Object.values(scope.dates).forEach(date => {
                                        if (
                                            date.forecast.amount &&
                                            date.forecast.date
                                        ) {
                                            const refDate = date.reference.date
                                            const forecastDate =
                                                date.forecast.date

                                            if (
                                                !project.forecastsReferences[
                                                    refDate
                                                ] &&
                                                refDate != undefined
                                            ) {
                                                project.forecastsReferences[
                                                    refDate
                                                ] = { amount: 0, percentage: 0 }
                                            }
                                            if (
                                                !project.currentForecasts[
                                                    forecastDate
                                                ]
                                            ) {
                                                project.currentForecasts[
                                                    forecastDate
                                                ] = { amount: 0, percentage: 0 }
                                            }

                                            // forecast reference data
                                            if (refDate) {
                                                project.forecastsReferences[
                                                    refDate
                                                ].amount += Number(
                                                    date.reference.amount || 0
                                                )
                                                project.forecastsReferences[
                                                    refDate
                                                ].percentage =
                                                    (project
                                                        .forecastsReferences[
                                                        forecastDate
                                                    ].amount *
                                                        100) /
                                                    project.price
                                            }

                                            // current forecast data
                                            if (forecastDate) {
                                                project.currentForecasts[
                                                    forecastDate
                                                ].amount += Number(
                                                    date.forecast.amount || 0
                                                )

                                                project.currentForecasts[
                                                    forecastDate
                                                ].percentage =
                                                    (project.currentForecasts[
                                                        forecastDate
                                                    ].amount *
                                                        100) /
                                                    project.price
                                            }
                                        }
                                    })
                                }
                            }
                        )
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        }
    },
    updated() {
        this.onResize()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        setForecastedPercentage(rowsByClient) {
            try {
                for (const clientId of Object.keys(rowsByClient)) {
                    rowsByClient[clientId].forecastedPercentage =
                        rowsByClient[clientId].totalBalanceToBill > 0
                            ? (rowsByClient[clientId].totalForecastedAmount *
                                  100) /
                              rowsByClient[clientId].totalBalanceToBill
                            : 0
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        parseCurrency(value) {
            const parts = value.split(' - ')
            return parts.map(part => part.trim())
        },
        rowsByClient(rowsByProject) {
            try {
                let rowsByClient = {}
                rowsByProject.forEach(row => {
                    if (rowsByClient[row.clientId]) {
                        rowsByClient[row.clientId].totalProjectsPrice +=
                            row.projectPrice
                        rowsByClient[row.clientId].totalBalanceToBill +=
                            row.balanceToBill
                        rowsByClient[row.clientId].totalBaseBalanceToBill +=
                            row.baseBalanceToBill
                        rowsByClient[row.clientId].totalInvoicedThisMonth +=
                            row.invoicedThisMonth
                        rowsByClient[row.clientId].totalInvoicedThisYear +=
                            row.invoicedThisYear
                        rowsByClient[row.clientId].totalActualVsForecasted +=
                            row.actualVsForecasted
                        rowsByClient[
                            row.clientId
                        ].totalActualVsForecastedInitial +=
                            row.actualVsForecastedInitial
                        rowsByClient[row.clientId].totalForecastedAmount +=
                            row.forecastedAmount
                    } else {
                        rowsByClient[row.clientId] = {
                            client: row.client,
                            totalProjectsPrice: row.projectPrice,
                            totalBalanceToBill: row.balanceToBill,
                            totalBaseBalanceToBill: row.baseBalanceToBill,
                            totalInvoicedThisMonth: row.invoicedThisMonth,
                            totalInvoicedThisYear: row.invoicedThisYear,
                            totalActualVsForecasted: row.actualVsForecasted,
                            totalActualVsForecastedInitial:
                                row.actualVsForecastedInitial,
                            totalForecastedAmount: row.forecastedAmount,
                        }
                    }
                    for (const header of this.originalHeaders.slice(10)) {
                        if (
                            rowsByClient[row.clientId][header.text] != undefined
                        ) {
                            rowsByClient[row.clientId][header.text] +=
                                row[header.text]
                        } else {
                            rowsByClient[row.clientId][header.text] =
                                row[header.text]
                        }
                    }
                })
                return rowsByClient
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculationActualVsForecasted(invoiced, amount) {
            if (invoiced > 0 && amount > 0) {
                return (invoiced * 100) / amount
            } else {
                return 0.0
            }
        },
        rowsByProject() {
            try {
                let rows = []
                this.projects.forEach(project => {
                    let row = {
                        sapCode: project.sapCode,
                        project: project.name,
                        projectManager: this.setUserName(
                            project.projectManager
                        ),
                        clientId: project.client,
                        client: this.setClientName(project.client),
                        lastUpdate: this.setLastUpdate(project),
                        currency: project.currency,
                        projectPrice: project.price || 0,
                        balanceToBill:
                            (project.price || 0) - (project.totalInvoiced || 0),
                        baseBalanceToBill: project.baseBalanceToBill,
                        invoicedThisMonth: project.invoicedThisMonth,
                        invoicedThisYear: project.invoicedThisYear,
                        ...(this.viewType == 'month' && {
                            actualVsForecasted: this.calculationActualVsForecasted(
                                project.invoicedThisMonth,
                                this.getDateAmount(project, this.currentDate)
                            ),
                        }),
                        ...(this.viewType == 'year' && {
                            actualVsForecasted: this.calculationActualVsForecasted(
                                project.invoicedThisYear,
                                this.getDateAmount(
                                    project,
                                    this.currentDate.split('-')[0]
                                )
                            ),
                        }),
                        actualVsForecastedInitial: this.calculationActualVsForecasted(
                            project.invoicedThisMonth,
                            project.newTotalAmount
                        ),
                        forecastedAmount:
                            Number(project.forecastedPercentage) == 0
                                ? 0
                                : (Number(project.forecastedPercentage) *
                                      project.price || 0) / 100,
                    }
                    // set dates
                    for (const header of this.originalHeaders.slice(10)) {
                        let typeHeader = ''
                        if (header.type == 'month') {
                            typeHeader = 'month'
                        } else {
                            typeHeader = 'year'
                        }
                        row[header.text] = this.getDateAmount(
                            project,
                            header[typeHeader]
                        )
                    }
                    if (row.balanceToBill > 0 || row.invoicedThisMonth > 0) {
                        rows.push(row)
                    }
                })
                return rows
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        closeReportSelect() {
            this.action = undefined
            this.copTRM = undefined
            this.usdTRM = undefined
            this.openReportSelector = false
        },
        openReportSelect() {
            this.openReportSelector = true
        },
        async getWeeklyForecast() {
            try {
                let projects = _.cloneDeep(this.projects)

                projects = projects.filter(
                    elm => elm.forecastV2 && elm.forecastV2 != null
                )

                for (let project of projects) {
                    if (project?.forecastV2) {
                        let lastProjection = 0
                        let currentMonth = moment().format('YYYY-MM')
                        Object.values(project.forecastV2.scopes).forEach(
                            scope => {
                                if (scope.dates) {
                                    Object.values(scope.dates).forEach(date => {
                                        if (date.reference?.date) {
                                            let referenceDate = moment(
                                                date.reference.date
                                            )
                                            if (
                                                referenceDate.isSame(
                                                    currentMonth
                                                )
                                            ) {
                                                lastProjection += Number(
                                                    date.reference.amount || 0
                                                )
                                            }
                                        }
                                    })
                                }
                            }
                        )
                        project.lastProjection = Number(lastProjection || 0)
                    }
                }
                // convert currencies
                projects.forEach(elm => {
                    if (this.selectedCurrency == 'USD') {
                        if (elm.currency == 'COP') {
                            elm.lastProjection =
                                elm.lastProjection / this.copTRM
                        }
                    } else if (this.selectedCurrency == 'COP') {
                        if (elm.currency == 'USD') {
                            elm.lastProjection =
                                elm.lastProjection * this.usdTRM
                        }
                    }
                })
                return projects
            } catch (error) {
                this.loading = false
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        getTRM(action) {
            this.action = action
            if (
                (this.selectedCurrency == 'COP' && this.usdTRM) ||
                (this.selectedCurrency == 'USD' && this.copTRM)
            ) {
                if (this.action == 'weeklyReport') {
                    this.downloadWeeklyReport()
                } else if (this.action == 'monthlyReport') {
                    this.downloadMonthlyReport()
                }
            }
        },
        async getWeeklyInvoicedData(data, date) {
            // get initial values
            data.forEach(project => {
                let projectInvoices = this.invoices.filter(
                    invoice => invoice.projectId == project.id
                )

                projectInvoices = projectInvoices.filter(
                    invoice =>
                        invoice.status != 'rejected' &&
                        invoice.status != 'inProgress' &&
                        invoice.billedOn
                )
                projectInvoices.forEach(invoice => {
                    invoice.date = this.formatDate2(invoice.billedOn._seconds)
                })

                let weeklyInvoiced = projectInvoices.filter(invoice => {
                    return invoice.date == date
                })

                let amountInvoiced = 0

                if (weeklyInvoiced.length > 0) {
                    weeklyInvoiced.forEach(invoice => {
                        if (invoice.items) {
                            Object.keys(invoice.items).forEach(quoteId => {
                                amountInvoiced += invoice.items[quoteId].reduce(
                                    (accumulator, item) =>
                                        accumulator +
                                        Number(item.currentInvoice || 0),
                                    0
                                )
                            })
                        }
                    })
                    project.weeklyInvoiced = Number(amountInvoiced || 0)
                }
            })

            data.forEach(elm => {
                if (this.selectedCurrency == 'USD') {
                    if (elm.currency == 'COP') {
                        elm.weeklyInvoiced = elm.weeklyInvoiced / this.copTRM
                    }
                } else if (this.selectedCurrency == 'COP') {
                    if (elm.currency == 'USD') {
                        elm.weeklyInvoiced = elm.weeklyInvoiced * this.usdTRM
                    }
                }
            })

            return data
        },
        async downloadWeeklyReport() {
            try {
                this.loading = true
                const forecastProjection = await this.getWeeklyForecast()

                // get invoiced this month
                const currentForecast = await this.getWeeklyInvoicedData(
                    this.projects,
                    this.currentDate
                )

                const weeklyReportData = currentForecast.filter(project => {
                    const matchedProjection = forecastProjection.find(
                        projection => projection.id === project.id
                    )
                    if (matchedProjection) {
                        if (
                            matchedProjection.lastProjection ||
                            project.weeklyInvoiced
                        ) {
                            project.lastProjection = Number(
                                matchedProjection.lastProjection || 0
                            )
                            project.currentInvoiced = Number(
                                project.weeklyInvoiced || 0
                            )
                        }
                        return true
                    }
                    return false
                })

                var columnOrder = [
                    'Client',
                    'Project',
                    'Projection',
                    'Current Invoiced',
                    'Difference',
                    '',
                    'Report Currency',
                    'Exchange',
                ]

                // sort minor mayor.
                let sortedProjects = Object.values(weeklyReportData).sort(
                    (a, b) =>
                        (Number(b.lastProjection) || 0) -
                        (Number(a.lastProjection) || 0)
                )

                // Delete projects without invoices or projections.
                for (let i = sortedProjects.length - 1; i >= 0; i--) {
                    let lastProjection =
                        Number(sortedProjects[i].lastProjection) || 0
                    let weeklyInvoiced =
                        Number(sortedProjects[i].weeklyInvoiced) || 0

                    if (lastProjection == 0 && weeklyInvoiced == 0) {
                        sortedProjects.splice(i, 1)
                    }
                }

                const Worksheet = XLSX.utils.json_to_sheet(
                    Object.values(sortedProjects).map((project, index) => {
                        let response = {
                            Client: this.setClientName(project.client),
                            Project: project.name,
                            Projection: this.formatCurrency(
                                this.selectedCurrency,
                                project.lastProjection
                            ),
                            'Current Invoiced': this.formatCurrency(
                                this.selectedCurrency,
                                project.currentInvoiced
                            ),
                            Difference: this.formatCurrency(
                                this.selectedCurrency,
                                Number(project.lastProjection) -
                                    Number(project.currentInvoiced)
                            ),
                        }
                        if (index === 0) {
                            response[''] = ''
                            response['Report Currency'] = this.selectedCurrency
                            response['Exchange'] =
                                this.selectedCurrency == 'COP'
                                    ? this.usdTRM
                                    : this.copTRM
                        }

                        return response
                    }),
                    { header: columnOrder }
                )

                const wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(
                    wb,
                    Worksheet,
                    'Clients Weekly Report'
                )

                if (this.viewType == 'month') {
                    XLSX.writeFile(
                        wb,
                        `weekly_forecasted_report${this.currentDate}.xlsx`
                    )
                } else {
                    XLSX.writeFile(
                        wb,
                        `weekly_forecasted_report${
                            this.currentDate.split('-')[0]
                        }.xlsx`
                    )
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.selectedCurrency = ''
                this.closeReportSelect()
            }
        },
        downloadMonthlyReport() {
            try {
                const dataByProject = this.rowsByProject()

                // Convert to selected TRM
                dataByProject.forEach(project => {
                    if (this.selectedCurrency == 'USD') {
                        if (project.currency == 'COP') {
                            project.projectPrice =
                                project.projectPrice / this.copTRM
                            project.balanceToBill =
                                project.balanceToBill / this.copTRM
                            project.baseBalanceToBill =
                                project.baseBalanceToBill / this.copTRM
                            project.invoicedThisMonth =
                                project.invoicedThisMonth / this.copTRM
                        }
                    } else if (this.selectedCurrency == 'COP') {
                        if (project.currency == 'USD') {
                            project.projectPrice =
                                project.projectPrice * this.usdTRM
                            project.balanceToBill =
                                project.balanceToBill * this.usdTRM
                            project.baseBalanceToBill =
                                project.baseBalanceToBill * this.usdTRM
                            project.invoicedThisMonth =
                                project.invoicedThisMonth * this.usdTRM
                        }
                    }

                    // format dinamic headers
                    for (const header of this.originalHeaders
                        .slice(10)
                        .filter(h => h.type == this.viewType)) {
                        let headerText = header.text
                        let value = project[headerText]

                        if (value != null) {
                            if (this.selectedCurrency == 'USD') {
                                if (project.currency == 'COP') {
                                    project[headerText] = value / this.copTRM
                                }
                            } else if (this.selectedCurrency == 'COP') {
                                if (project.currency == 'USD') {
                                    project[headerText] = value * this.usdTRM
                                }
                            }
                        }
                    }
                })

                // configure columns
                var columnOrder = []
                if (this.viewType == 'year') {
                    columnOrder = [
                        'SAP CODE',
                        'Project',
                        'Client',
                        'Currency',
                        'Project Price',
                        'Current Balance To Bill',
                        'Base Balance To Bill',
                    ]
                }

                const currentDate = moment()
                    .format('MMM-YYYY')
                    .toUpperCase()

                // sort minor mayor
                const sortedProjects = Object.values(dataByProject).sort(
                    (a, b) =>
                        (Number(b[currentDate]) || 0) -
                        (Number(a[currentDate]) || 0)
                )

                const Worksheet = XLSX.utils.json_to_sheet(
                    Object.values(sortedProjects).map((project, index) => {
                        let response = {
                            'SAP CODE': project.sapCode,
                            Project: project.project,
                            Client: project.client,
                            Currency: project.currency,
                            'Project Price': this.formatCurrency(
                                this.selectedCurrency,
                                project.projectPrice
                            ),
                            'Current Balance To Bill': this.formatCurrency(
                                this.selectedCurrency,
                                project.balanceToBill
                            ),
                            'Base Balance To Bill': this.formatCurrency(
                                this.selectedCurrency,
                                project.baseBalanceToBill
                            ),
                        }
                        // forecast months
                        for (const header of this.originalHeaders
                            .slice(10)
                            .filter(h => h.type == this.viewType)) {
                            response[header.text] =
                                this.formatCurrency(
                                    this.selectedCurrency,
                                    project[header.text]
                                ) || ''
                        }
                        if (index === 0) {
                            response[''] = ''
                            response['Report Currency'] =
                                this.selectedCurrency || ''
                            response['Exchange'] =
                                this.selectedCurrency == 'COP'
                                    ? this.usdTRM
                                    : this.copTRM
                        }
                        return response
                    }),
                    { header: columnOrder }
                )
                const wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(wb, Worksheet, 'Clients Report')
                if (this.viewType == 'month') {
                    XLSX.writeFile(
                        wb,
                        `forecasted_by_project_${this.currentDate}.xlsx`
                    )
                } else {
                    XLSX.writeFile(
                        wb,
                        `forecasted_by_project_${
                            this.currentDate.split('-')[0]
                        }.xlsx`
                    )
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.selectedCurrency = ''
                this.closeReportSelect()
            }
        },
        downloadReportByClient() {
            try {
                // accumulate by project
                const dataByProject = this.rowsByProject()
                // accumulate by client
                const dataByClient = this.rowsByClient(dataByProject)
                // set forecasted percentage
                this.setForecastedPercentage(dataByClient)
                // create file
                var columnOrder = []
                if (this.viewType == 'year') {
                    columnOrder = [
                        'Client',
                        'Total price of the projects',
                        'Total current balance to bill',
                        'Total base balance to bill',
                        'Total invoiced this year',
                        'Actual Vs Forecasted (in the year)',
                        'Total forecasted percentage',
                    ]
                }
                const Worksheet = XLSX.utils.json_to_sheet(
                    Object.values(dataByClient).map(client => {
                        let response = {
                            Client: client.client,
                            'Total price of the projects': this.formatNumber(
                                client.totalProjectsPrice
                            ),
                            'Total current balance to bill': this.formatNumber(
                                client.totalBalanceToBill
                            ),
                            'Total base balance to bill': this.formatNumber(
                                client.totalBaseBalanceToBill
                            ),
                            ...(this.viewType == 'month' && {
                                'Total invoiced this month': this.formatNumber(
                                    client.totalInvoicedThisMonth
                                ),
                                'Actual Vs Forecasted (in the month)':
                                    client.totalActualVsForecasted.toFixed(2) +
                                    '%',
                            }),
                            ...(this.viewType == 'year' && {
                                'Total invoiced this year': this.formatNumber(
                                    client.totalInvoicedThisYear
                                ),
                                'Actual Vs Forecasted (in the year)':
                                    client.totalActualVsForecasted.toFixed(2) +
                                    '%',
                            }),
                            ...(this.viewType == 'month' && {
                                'Actual Vs Forecasted (INITIAL)':
                                    client.totalActualVsForecastedInitial.toFixed(
                                        2
                                    ) + '%',
                            }),
                            'Total forecasted percentage':
                                (client.totalBalanceToBill > 0
                                    ? (
                                          (client.totalForecastedAmount * 100) /
                                          client.totalBalanceToBill
                                      ).toFixed(2)
                                    : 0.0) + '%',
                        }
                        for (const header of this.originalHeaders
                            .slice(10)
                            .filter(h => h.type == this.viewType)) {
                            response[header.text] = this.formatNumber(
                                client[header.text]
                            )
                        }
                        return response
                    }),
                    { header: columnOrder }
                )
                // create excel work book
                const wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(wb, Worksheet, 'Clients Report')
                if (this.viewType == 'month') {
                    XLSX.writeFile(
                        wb,
                        `forecasted_by_client_${this.currentDate}.xlsx`
                    )
                } else {
                    XLSX.writeFile(
                        wb,
                        `forecasted_by_client_${
                            this.currentDate.split('-')[0]
                        }.xlsx`
                    )
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
            } = this.$refs
            this.height = containerHeight - 180
        },
        async getInvoices() {
            this.invoices = await API.getInvoices({})
            this.filteredForecasts.forEach(project => {
                let projectInvoices = this.invoices.filter(
                    invoice => invoice.projectId == project.id
                )
                projectInvoices = projectInvoices.filter(
                    invoice =>
                        invoice.status != 'rejected' &&
                        invoice.status != 'inProgress' &&
                        invoice.billedOn
                )
                projectInvoices.forEach(invoice => {
                    invoice.date = this.formatDate2(invoice.billedOn._seconds)
                })

                let invoicedThisMonth = projectInvoices.filter(
                    invoice => invoice.date == this.currentDate
                )

                let invoicedThisYear = projectInvoices.filter(invoice =>
                    invoice.date.includes(this.currentDate)
                )

                let amountInvoicedThisMonth = 0
                let amountInvoicedThisYear = 0

                invoicedThisMonth.forEach(invoice => {
                    if (invoice.items) {
                        Object.keys(invoice.items).forEach(quoteId => {
                            amountInvoicedThisMonth += invoice.items[
                                quoteId
                            ].reduce(
                                (accumulator, item) =>
                                    accumulator +
                                    (Number(item.billedQty || 0) -
                                        Number(item.creditQty || 0)) *
                                        item.pricePerUnit,
                                0
                            )
                        })
                    }
                })

                invoicedThisYear.forEach(invoice => {
                    if (invoice.items) {
                        Object.keys(invoice.items).forEach(quoteId => {
                            amountInvoicedThisYear += invoice.items[
                                quoteId
                            ].reduce(
                                (accumulator, item) =>
                                    accumulator +
                                    (Number(item.billedQty || 0) -
                                        Number(item.creditQty || 0)) *
                                        item.pricePerUnit,
                                0
                            )
                        })
                    }
                })

                project.invoicedThisMonth = amountInvoicedThisMonth || 0
                project.invoicedThisYear = amountInvoicedThisYear || 0
                project.baseBalanceToBill =
                    project.price -
                    ((project.totalInvoiced || 0) -
                        (amountInvoicedThisMonth || 0))
                project.balanceToBill =
                    project.price - (project.totalInvoiced || 0)
            })
        },
        formatDate2(seconds) {
            return `${moment.unix(seconds).format('YYYY-MM')}`
        },
        setHeaders() {
            this.currentMonth = this.months[new Date().getMonth()]
            const currentYear = new Date().getFullYear()
            const invoicedHeaderText = `INVOICED IN ${this.currentMonth}`
            const invoicedHeaderTextYear = `INVOICED IN ${currentYear}`
            this.originalHeaders.push(
                ...[
                    {
                        text: invoicedHeaderText,
                        value: 'invoicedThisMonth',
                        align: 'center',
                        sortable: false,
                        width: 150,
                        class: 'gray-background',
                        type: 'month',
                    },
                    {
                        text: invoicedHeaderTextYear,
                        value: 'invoicedThisYear',
                        align: 'center',
                        sortable: false,
                        width: 150,
                        class: 'gray-background',
                        type: 'year',
                    },
                    {
                        text: 'ACTUAL VS FORECASTED (INITIAL)',
                        value: 'actualVsForecastedInitial',
                        align: 'center',
                        sortable: false,
                        width: 150,
                        class: 'gray-background',
                        type: 'month',
                    },
                    {
                        text: 'FORECASTED PERCENTAGE',
                        value: 'forecastedPercentage',
                        align: 'center',
                        sortable: false,
                        width: 150,
                        class: 'gray-background',
                    },
                ]
            )

            this.filteredForecasts.forEach(project => {
                if (project.forecastV2) {
                    Object.values(project.forecastV2.scopes).forEach(scope => {
                        if (scope.dates) {
                            Object.values(scope.dates).forEach(date => {
                                if (
                                    date.reference.date &&
                                    moment(date.reference.date).isSameOrAfter(
                                        this.currentDate
                                    )
                                ) {
                                    this.dateToShow.push(date.reference.date)
                                }
                            })
                        }
                    })
                }
            })
            this.dateToShow = [...new Set(this.dateToShow.sort())]
            const maxDate = this.dateToShow[this.dateToShow.length - 1]
            this.dateToShow = this.monthsBetweenDates(
                moment(this.currentDate),
                moment(maxDate)
            )

            let yearClasses = {}
            let toggleClass = true

            this.dateToShow.forEach(date => {
                const dateParts = date.split('-')
                const monthIndex = Number(dateParts[1]) - 1
                const year = dateParts[0]
                const dateText = `${this.months[monthIndex]}-${year}`

                if (!yearClasses[year]) {
                    yearClasses[year] = toggleClass
                        ? 'dark-gray-background'
                        : 'gray-background'
                    toggleClass = !toggleClass
                }

                this.originalHeaders.push({
                    text: dateText,
                    value: 'date',
                    align: 'center',
                    sortable: false,
                    width: 100,
                    type: 'month',
                    class: yearClasses[year],
                    month: date,
                })
            })

            this.dateToShow.forEach(date => {
                const dateParts = date.split('-')
                const year = dateParts[0]
                const existent = this.originalHeaders.find(h => h.year == year)
                if (!existent) {
                    this.originalHeaders.push({
                        text: year,
                        value: 'dateYear',
                        align: 'center',
                        sortable: false,
                        width: 100,
                        type: 'year',
                        class: yearClasses[year],
                        year,
                    })
                }
            })

            this.insights[1].title = `INVOICED IN ${this.currentMonth}`
        },
        filterByProject(item) {
            return (
                item.name &&
                item.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        filterByProjectManager(item) {
            return (
                item.projectManager &&
                this.setUserName(item.projectManager)
                    .toLowerCase()
                    .includes(this.projectManagerToFilter.toLowerCase())
            )
        },
        filterByForecastedPercentage(item) {
            return item.forecastedPercentage
                .toString()
                .toLowerCase()
                .includes(
                    this.forecastedPercentageToFilter.toString().toLowerCase()
                )
        },
        showMonths() {
            this.headers = this.originalHeaders.filter(h => h.type != 'year')
            this.viewType = 'month'
        },
        showYears() {
            this.headers = _.cloneDeep(
                this.originalHeaders.filter(h => h.type != 'month')
            )
            this.viewType = 'year'
        },
        monthsBetweenDates(startDate, endDate) {
            let datesObject = []
            let currentDay = startDate
            let dates = []
            while (currentDay.isSameOrBefore(endDate)) {
                dates.push(currentDay.format('YYYY-MM'))
                datesObject[currentDay.format('YYYY-MM')] = 0
                currentDay.add(1, 'month')
            }
            return dates
        },
        setUserName(userId) {
            let userName = ''
            const user = this.users.find(u => u.id == userId)
            if (user) {
                userName = user.name
            }
            return userName
        },
        setClientName(clientId) {
            let clientName = ''
            const client = this.clients.find(c => c.id == clientId)
            if (client) {
                clientName = client.name
            }
            return clientName
        },
        setLastUpdate(item) {
            let lastUpdate = { _seconds: 0, _nanoseconds: 0 }
            if (item.forecastV2) {
                Object.values(item.forecastV2?.scopes).forEach(scope => {
                    if (
                        scope.updatedOn &&
                        scope.updatedOn._seconds > lastUpdate._seconds
                    ) {
                        lastUpdate = scope.updatedOn
                    }
                })
            }
            return lastUpdate._seconds > 0
                ? this.formatDate(lastUpdate._seconds)
                : '-'
        },
        formatCurrency(currency, value) {
            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: currency,
                maximumFractionDigits: 0,
            }).format(value)
        },
        formatNumber(value) {
            return new Intl.NumberFormat('de-DE', {
                maximumFractionDigits: 0,
            }).format(value)
        },
        calculateForecastedPercentage(item) {
            let forecastedAmount = 0

            if (item.forecastV2) {
                Object.values(item.forecastV2.scopes).forEach(scope => {
                    if (scope.dates) {
                        Object.values(scope.dates).forEach(date => {
                            if (date.reference?.date) {
                                let referenceDate = moment(date.reference.date)
                                if (
                                    referenceDate.isSameOrAfter(
                                        this.currentDate
                                    )
                                ) {
                                    forecastedAmount += Number(
                                        date.reference.amount || 0
                                    )
                                }
                            }
                        })
                    }
                })
            }
            let price = Number(item.price) || 0
            let invoiced = Number(item.totalInvoiced) || 0

            if (price === 0) {
                return { percentage: '0.00', forecastedAmount }
            }

            let invoicedPercentage = (invoiced * 100) / price
            let projectedPercentage = (forecastedAmount * 100) / price

            return {
                percentage: (invoicedPercentage + projectedPercentage).toFixed(
                    2
                ),
                forecastedAmount,
            }
        },
        totalPerDate(date) {
            let totalInDate = { USD: 0, COP: 0 }
            this.filteredForecasts.forEach(project => {
                if (project.forecastV2) {
                    Object.values(project.forecastV2.scopes).forEach(scope => {
                        if (scope.dates) {
                            Object.values(scope.dates).forEach(forecastDate => {
                                if (date.includes('-')) {
                                    if (forecastDate?.reference?.date) {
                                        if (
                                            forecastDate.reference.date == date
                                        ) {
                                            totalInDate[
                                                project.currency
                                            ] += Number(
                                                forecastDate.reference.amount
                                            )
                                        }
                                    }
                                } else {
                                    if (forecastDate.reference) {
                                        if (
                                            forecastDate?.reference?.date.includes(
                                                date
                                            ) &&
                                            moment(
                                                forecastDate.reference.date
                                            ).isSameOrAfter(this.currentDate)
                                        ) {
                                            totalInDate[
                                                project.currency
                                            ] += Number(
                                                forecastDate.reference.amount
                                            )
                                        }
                                    }
                                }
                            })
                        }
                    })
                }
            })
            const currencyCOP = this.formatCurrency('COP', totalInDate.COP)
            const currencyUSD = this.formatCurrency('USD', totalInDate.USD)
            return currencyUSD + ' - ' + currencyCOP
        },
        totalProjectsPrice() {
            const usdProjects = this.filteredForecasts.filter(
                project => project.currency == 'USD'
            )
            const copProjects = this.filteredForecasts.filter(
                project => project.currency == 'COP'
            )
            const totalUSD = usdProjects.reduce(
                (accumulator, project) =>
                    accumulator + Number(project.price) || 0,
                0
            )
            const totalCOP = copProjects.reduce(
                (accumulator, project) =>
                    accumulator + Number(project.price) || 0,
                0
            )
            return (
                this.formatCurrency('USD', totalUSD) +
                ' - ' +
                this.formatCurrency('COP', totalCOP)
            )
        },
        totalProjectsB2B() {
            const usdProjects = this.filteredForecasts.filter(
                project => project.currency == 'USD'
            )
            const copProjects = this.filteredForecasts.filter(
                project => project.currency == 'COP'
            )
            const totalUSD = usdProjects.reduce(
                (accumulator, project) =>
                    accumulator +
                    (Number(project.price || 0) -
                        Number(project.totalInvoiced || 0)),
                0
            )
            const totalCOP = copProjects.reduce(
                (accumulator, project) =>
                    accumulator +
                    (Number(project.price || 0) -
                        Number(project.totalInvoiced || 0)),
                0
            )
            return (
                this.formatCurrency('USD', totalUSD) +
                ' - ' +
                this.formatCurrency('COP', totalCOP)
            )
        },

        totalProjectsBaseB2B() {
            const usdProjects = this.filteredForecasts.filter(
                project => project.currency == 'USD'
            )
            const copProjects = this.filteredForecasts.filter(
                project => project.currency == 'COP'
            )

            let totalCOP = 0
            let totalUSD = 0
            copProjects.forEach(project => {
                if (project.baseBalanceToBill) {
                    totalCOP += Number(project.baseBalanceToBill || 0)
                }
            })

            usdProjects.forEach(project => {
                if (project.baseBalanceToBill) {
                    totalUSD += Number(project.baseBalanceToBill || 0)
                }
            })

            return { usd: totalUSD, cop: totalCOP }
        },
        totalForecasted() {
            let totalForecasted = { USD: 0, COP: 0 }
            this.projects.forEach(project => {
                if (project.forecastV2) {
                    Object.values(project.forecastV2.scopes).forEach(scope => {
                        if (scope.dates) {
                            Object.values(scope.dates).forEach(forecastDate => {
                                if (forecastDate?.reference?.date) {
                                    totalForecasted[project.currency] += Number(
                                        forecastDate.reference.amount
                                    )
                                }
                            })
                        }
                    })
                }
            })
            return { cop: totalForecasted.COP, usd: totalForecasted.USD }
        },
        openProject(project) {
            this.selectedProject = _.cloneDeep(project)
            this.detailView = true
        },
        closeDetailView() {
            this.detailView = false
            this.selectedProject = {}
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('MMM DD YYYY')}`
        },
        getDatePercentage(item, date) {
            const projectPrice = Number(item.price || 0)
            if (item.forecastV2) {
                if (date.includes('-')) {
                    const amount = this.getDateAmount(item, date)
                    return projectPrice > 0
                        ? ((amount * 100) / projectPrice).toFixed(2)
                        : 0.0
                } else {
                    const amount = this.getDateAmount(item, date)
                    return projectPrice > 0
                        ? ((amount * 100) / projectPrice).toFixed(2)
                        : 0.0
                }
            }
        },
        getDateAmount(item, date) {
            if (item.forecastV2) {
                let totalAmount = 0
                if (date.includes('-')) {
                    Object.values(item.forecastV2?.scopes).forEach(scope => {
                        Object.values(scope?.dates).forEach(forecastDate => {
                            if (forecastDate?.reference.date == date) {
                                totalAmount += Number(
                                    forecastDate.reference.amount || 0
                                )
                            }
                        })
                    })
                } else {
                    Object.values(item.forecastV2?.scopes).forEach(scope => {
                        Object.values(scope?.dates).forEach(forecastDate => {
                            if (forecastDate.reference != 0) {
                                if (
                                    forecastDate?.reference.date.split(
                                        '-'
                                    )[0] == date
                                ) {
                                    totalAmount += Number(
                                        forecastDate.reference.amount || 0
                                    )
                                }
                            }
                        })
                    })
                }
                return totalAmount
            }
            return 0
        },
        totalInvoicedInMonth() {
            const usdProjects = this.filteredForecasts.filter(
                project => project.currency == 'USD'
            )
            const copProjects = this.filteredForecasts.filter(
                project => project.currency == 'COP'
            )
            const totalUSD = usdProjects.reduce(
                (accumulator, project) =>
                    accumulator + Number(project.invoicedThisMonth || 0),
                0
            )
            const totalCOP = copProjects.reduce(
                (accumulator, project) =>
                    accumulator + Number(project.invoicedThisMonth || 0),
                0
            )
            return { usd: totalUSD, cop: totalCOP }
        },
        totalActualForecasted() {
            // convert the COP values in USD based on company exchange
            const copInvoicedInUsd =
                this.totalInvoicedInMonth().cop / this.companyUsdExchange
            const copForecastedInUsd =
                this.totalForecasted().cop / this.companyUsdExchange

            // sum the previous formatted values
            const totalInvoiced =
                this.totalInvoicedInMonth().usd + copInvoicedInUsd
            const totalForecasted =
                this.totalForecasted().usd + copForecastedInUsd

            if (isNaN(totalForecasted) || totalForecasted === 0) {
                return 0.0
            }

            return (totalInvoiced * 100) / totalForecasted
        },
        totalForecastedPercentage() {
            let totalAmountForecasted = 0
            let totalProjectsPrices = 0
            let totalProjectsInvoiced = 0
            this.filteredForecasts.forEach(project => {
                totalAmountForecasted += this.calculateForecastedPercentage(
                    project
                ).forecastedAmount
                totalProjectsPrices += Number(project.price || 0)
                totalProjectsInvoiced += Number(project.totalInvoiced || 0)
            })

            let invoicedPercentage =
                (Number(totalProjectsInvoiced || 0) * 100) / totalProjectsPrices
            let projectedPercentage =
                (Number(totalAmountForecasted || 0) * 100) / totalProjectsPrices

            return invoicedPercentage + projectedPercentage
        },
        sumNewAmount(projects) {
            const currentDate = moment().format('YYYY-MM')
            for (let project of projects) {
                let newTotalAmount = 0
                if (project.forecastV2 && project.forecastV2.scopes) {
                    Object.values(project.forecastV2.scopes).forEach(scope => {
                        if (scope.dates) {
                            Object.values(scope.dates).forEach(date => {
                                if (date?.reference?.date) {
                                    const referenceDate = moment(
                                        date.reference.date,
                                        'YYYY-MM'
                                    )
                                    if (
                                        referenceDate.isValid() &&
                                        referenceDate.isSameOrAfter(
                                            currentDate,
                                            'month'
                                        )
                                    ) {
                                        // Suma el amount si está disponible
                                        if (date.reference.amount) {
                                            newTotalAmount += Number(
                                                date.reference.amount
                                            )
                                        }
                                    }
                                }
                            })
                        }
                    })
                }
                project.newTotalAmount = newTotalAmount
            }
            return projects
        },
        setUpdate(newEntry, id) {
            const forecastIndex = this.filteredForecasts.findIndex(
                quote => quote.id == id
            )
            if (forecastIndex > -1) {
                this.filteredForecasts[forecastIndex].forecastV2 = newEntry
                this.sumNewAmount(this.projects)
                this.filteredForecasts[
                    forecastIndex
                ].forecastedPercentage = this.calculateForecastedPercentage(
                    this.filteredForecasts[forecastIndex]
                ).percentage
            }
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
#virtual-scroll-table {
    width: 100%;
    overflow-y: auto;
}

.text-orange {
    color: orange;
    font-weight: bold;
}
.text-green {
    color: green;
    font-weight: bold;
}
.text-red {
    color: red;
    font-weight: bold;
}
.gray-background {
    background-color: #eee;
}

.white-background {
    background-color: white;
}

.dark-gray-background {
    background-color: #c3c3c3;
}

.custom-label .v-label {
    font-size: 12px;
}

.forecast-table td:first-child {
    position: sticky;
    left: 0;
    background: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
}
.forecast-table th {
    position: sticky;
    top: 0;
    background: white;
    z-index: 2;
}
.forecast-table th:first-child {
    position: sticky;
    left: 0;
    background: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 3;
}
.v-data-table-header th {
    background-color: #eeeeee !important;
}
thead {
    position: sticky;
    z-index: 2;
}
.v-data-table__wrapper {
    max-height: 63vh;
    overflow-y: auto;
}
</style>
